var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"dashboard__container"},[_vm._m(0),_c('div',{staticClass:"dashboard__container--body pt-3"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.myGroupsNewApplications,"styleClass":"vgt-table condensed","search-options":{
              enabled: true,
              placeholder: 'Search this table',
            },"pagination-options":{
              enabled: true,
              mode: 'records',
              perPage: 20,
            }},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):(props.column.field == 'owner')?_c('span',[(props.row.owner)?_c('span',[_vm._v(" "+_vm._s(props.row.owner.firstName)+" ")]):_vm._e(),(props.row.owner)?_c('span',[_vm._v(" "+_vm._s(props.row.owner.lastName)+" ")]):_vm._e()]):(props.column.field == 'applicants')?_c('span',[(props.row.applicants)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(props.row.applicants.length)+" ")]):_vm._e()]):(props.column.field == 'members')?_c('span',[(props.row.members)?_c('span',[_vm._v(" "+_vm._s(props.row.members.length)+" ")]):_vm._e()]):(props.column.field == 'admins')?_c('span',[(props.row.admins && props.row.admins.length > 0)?_c('span',_vm._l((props.row.admins),function(item,index){return _c('button',{key:item.id,staticClass:"btn btn__small chip mr-2"},[_vm._v(_vm._s(item.name))])}),0):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),(!_vm.userProfile)?_c('Loader'):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__container--header"},[_c('h1',[_vm._v("Dashboard")])])}]

export { render, staticRenderFns }